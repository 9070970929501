@import "@repalash/blueprint-styler/base/tokens";

.react-flow__edge-path, .react-flow__connection-path {
  stroke-linecap: round;
  stroke: #979da1;
  stroke-width: 2;
  fill: none;
  opacity: 0.65;

  .no-edge-colors &{
    stroke: #979da1 !important;
  }
  .hide-edges & , .hide-nodes &{
    display: none;
  }
}

.react-flow__edge.animated path {
  /*stroke-dasharray: 20, 12;*/
  /*animation: 0.5s linear 0s infinite normal none running dashdraw2;*/
}

@-webkit-keyframes dashdraw2 {
  from {
    stroke-dashoffset: 32;
  }
}
@keyframes dashdraw2 {
  from {
    stroke-dashoffset: 32;
  }
}
.react-flow__edge-path, .react-flow__connection-path {
  .edge-object3dIn &{
    //stroke: #f21111;
    stroke: $blue1;
    .bp5-dark &{
      stroke: $blue4;
    }
  }
  .edge-textureIn &{
    //stroke: #51f211;
    //stroke: $gray4;
    stroke: $green1;
    .bp5-dark &{
      stroke: $green4;
    }
  }
  .edge-shaderFragIn &{
    stroke: $cerulean1;
    .bp5-dark &{
      stroke: $cerulean4;
    }
  }
  .edge-cameraIn &{
    stroke: $orange1;
    .bp5-dark &{
      stroke: $orange4;
    }
  }
  .edge-bufferIn &{
    stroke: $red1;
    .bp5-dark &{
      stroke: $red4;
    }
  }
  .edge-materialIn &{
    stroke: $sepia4;
    .bp5-dark &{
      stroke: $sepia3;
    }
  }
  .edge-shaderIn &{
    stroke: $gray1;
    .bp5-dark &{
      stroke: $gray4;
    }
  }
  .vector-handle{
    stroke: $gold4;
    .bp5-dark &{
      stroke: $gold3;
    }
  }
  .string-handle{
    stroke: hsla($gray1-hsl, 0.1);
    .bp5-dark &{
      stroke: hsla($gray4-hsl, 0.1);
    }
  }

}
