@import "@repalash/blueprint-styler/base/tokens";

// region welcome dialog
#welcome-dialog{

}

#welcome-sidebar{
  width: calc(20 * $pt-grid-size) !important;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 0 0 $pt-divider-black;

  background: $light-gray5;
}
.bp5-dark #welcome-sidebar{
  background: $black;
  //box-shadow: 1px 0 0 0 $pt-divider-black;
}
#welcome-content{
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: $pt-grid-size;

}

#welcome-sidebar-logo{
  padding: calc(2 * $pt-grid-size) calc(1 * $pt-grid-size);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--pt-grid-size) / 1);
}
.welcome-sidebar-list-button{
  padding: $pt-grid-size calc(1.5 * $pt-grid-size);
  margin-left: $pt-grid-size;
  margin-right: $pt-grid-size;
  margin-bottom: calc(1 * $pt-grid-size) !important;
  border-radius: calc(0.5 * $pt-grid-size) !important;
  width: calc(100% - 2 * $pt-grid-size) !important;

  //&:hover{
  //    background: $gray2;
  //}
  //&.bp5-active{
  //    background: $gray2;
  //}

  .bp5-icon{
    //color: transparent!important;
    width: calc(2 * $pt-grid-size);
  }
}
.welcome-dialog-empty-actions{
  gap: calc(0.5 * $pt-grid-size);
  .bp5-icon{
    //color: transparent!important;
    width: calc(2 * $pt-grid-size);
  }
}
.bp5-dark .welcome-sidebar-list-button {
  //&:hover{
  //    background: $dark-gray2;
  //}
  //&.bp5-active{
  //    background: $dark-gray2;
  //}
}
.welcome-dialog-empty-actions{
  display: flex;
  flex-direction: column;
  //gap: $pt-grid-size;
  padding: $pt-grid-size;;
  width: 300px;
  //max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.welcome-main-create-actions{
  max-width: 100%;
  gap: calc($pt-grid-size * 1);
  margin-bottom: calc($pt-grid-size * 2);
}
.welcome-main-create-actions button{
  display: flex;
  flex-direction: column;
  flex: 0 1 25% !important;
  text-align: center !important;
  border-radius: calc($pt-border-radius * 2) !important;
  justify-content: space-between !important;
  gap: calc($pt-grid-size * 1.5);
  padding: calc($pt-grid-size * 1.5) $pt-grid-size $pt-grid-size;
}
.welcome-main-create-actions button .bp5-button-text{
  flex-grow: 0 !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  max-width: 100%;
  height: calc(2 * $pt-line-height * $pt-font-size - 2px) !important;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.welcome-main-create-actions button .bp5-icon{
  transform: scale(1.5);
}
.welcome-main-create-actions button .bp5-icon-share{
  position: absolute;
  transform: scale(1) !important;
  top: calc($pt-grid-size * 0.5);
  right: calc($pt-grid-size * 0.5);
}
#welcome-sidebar-list-button-settings{
  margin-top: auto !important;
}

// endregion welcome dialog
