@import "./flow-handles.scss";
@import "./flow-edges.scss";
@import "./card-node.scss";

.react-flow{
  flex-grow: 1;
  //padding-top: $pt-navbar-height;
}

.react-flow{
  --flow-background-light: 212, 52%, 94%;
  background: hsla(var(--flow-background-light),1) !important;
  //background: $light-gray1 !important;
}
.bp5-dark .react-flow{
  --flow-background-dark: var(--dark-gray1-hsl);
  background: hsla(var(--flow-background-dark),1) !important;
}

// edges on top of nodes
//.react-flow__node {
//  z-index: -1 !important;
//}
.react-flow__edges.react-flow__container{
  z-index: 1 !important;
}

.react-flow__attribution{
  display: none;
}
