@import "@repalash/blueprint-styler/base/tokens";

.code-drawer{
  top: $pt-navbar-height !important;
  height: calc(100% - #{$pt-navbar-height});
  pointer-events: auto;
}

.bp5-overlay-open:has(.code-drawer){
    pointer-events: none;
}

.shader-error-container{
  top: $pt-navbar-height !important;
  height: calc(100% - #{$pt-navbar-height});
  position: absolute;
  left: 0;
  width: 50%;
  z-index: 100;
  background: #282C34ee;
  overflow-y: scroll;
  font-size: 1.25rem;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "blueprint-icons-16", sans-serif;
}

.shader-error-cross{
  float: right;
  //position: absolute;
  //top: 0;
  //right: 0;
  //padding: 0.5rem;
  //cursor: pointer;
}
.shader-error {
  padding: 1rem;
  color: rgb(252, 207, 207);
}
.shader-error pre {
  margin-top: 0;
  white-space: pre-wrap;
}
