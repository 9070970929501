@import "@repalash/blueprint-styler/base/tokens";

.popup-dialog{
  //width: 100%;
  //height: 100%;
  display: flex;
  flex-direction: row;
  //gap: $pt-grid-size;
  //padding: $pt-grid-size;
  //overflow: hidden;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: min(100%, 700px);
  height: min(100%, 520px);
  padding: 0;

  outline: 1px solid $pt-divider-black;
  background: $light-gray4;

  .bp5-dark & {
    background: $dark-gray1;}
}

.popup-main-container{
  width: 100%;
  padding: calc($pt-grid-size * 2);
  display: flex;
  flex-direction: column;
  gap: calc($pt-grid-size * 1);
  overflow-y: scroll;
}
