@import "@repalash/blueprint-styler/base/tokens";

.bp5-button{
  user-select: none;
  transition: background-color 0.2s;
}

.bp5-control-group{
  align-items: center;
}

// used in welcome dialog
.bp5-html-table td{
  vertical-align: middle !important;
}

label.bp5-label .bp5-html-select, label.bp5-label .bp5-input, label.bp5-label .bp5-popover-wrapper, label.bp5-label .bp5-select, label.bp5-label .bp5-slider{
  margin-top: 0;
}
.bp5-dark {
  .bp5-divider {
    border-bottom: 1px solid var(--pt-divider-white);
    border-right: 1px solid var(--pt-divider-white);
  }
}

// region .bp5-small

.bp5-small{

  &.bp5-inline{
    &.bp5-label{
      line-height: 25px;
    }
    &.bp5-form-group .bp5-label {
        line-height: 25px;
    }
  }

  &.bp5-checkbox, &.bp5-label, &.bp5-control-group, > .bp5-label{
    font-size: $pt-font-size-small;
  }

  &.bp5-html-select select, &.bp5-select select{
    font-size: $pt-font-size-small;
    height: 25px;
  }

  &.bp5-html-select .bp5-icon, &.bp5-select .bp5-icon{
    right: 4px;
    top: 5px;

    > svg{
      width: 14px;
      height: 14px;
    }
  }

  &.bp5-input-group .bp5-input-action > .bp5-spinner, &.bp5-input-group > .bp5-icon, &.bp5-input-group > .bp5-input-left-container > .bp5-icon{
    margin: 5px;
    > svg{
      width: 14px;
      height: 14px;
    }
  }
}

// endregion .bp5-small

// region tooltip (small rounded tooltip styling)
.bp5-tooltip{
  font-size: $pt-font-size-small;

  > .bp5-popover-content{
    padding: calc($pt-grid-size / 2) $pt-grid-size;
  }
}

.bp5-popover .bp5-popover-content{
  border-radius: 2rem;
}

.bp5-popover.bp5-tooltip{
  border-radius: 2rem;
  .bp5-popover-content{
    border-radius: 2rem;
  }
  .bp5-popover-arrow{
    width: 1rem;
    height: 1rem;
  }

  &.bp5-popover-placement-bottom{
    translate: 0 -6px;
    .bp5-popover-arrow{
      translate: 0 6px;
    }
  }
  &.bp5-popover-placement-right{
    translate: 6px 0;
    .bp5-popover-arrow{
      translate: 6px 0;
    }
  }
  &.bp5-popover-placement-left{
    translate: -6px 0;
    .bp5-popover-arrow{
      translate: -6px 0;
    }
  }
  &.bp5-popover-placement-top{
    translate: 0 6px;
    .bp5-popover-arrow{
      translate: 0 -6px;
    }
  }
}
// endregion tooltip

// region numeric
.numberInput{
  flex-shrink: 1 !important;
  width: auto !important;

  .bp5-input-group{
    flex-shrink: 1 !important;
  }
  .bp5-button-group{
    display: none;
  }
}
.num-input-form-group{
  justify-content: space-between; // todo see if required in all form-group
}
.num-input-form-group .bp5-label{
  max-width: 50%;
  white-space: nowrap;
}
// endregion numeric

// region running text

.bp5-running-text {

  //font-size: var(--pt-font-size); // set in jsx
  line-height: 1.5;

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 10px;
    margin-top: 20px
  }

  hr {
    margin: 10px 0
  }

  p {
    margin: 0 0 5px;
    padding: 0
  }

  strong {
    font-weight: 800;
  }

  .bp5-callout {
    margin: 15px 0
  }

  h1,h1.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 36px);
    line-height: calc(var(--ce-font-scale, 1) * 40px);
  }

  h2,h2.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 28px);
    line-height: calc(var(--ce-font-scale, 1) * 32px);
  }

  h3,h3.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 22px);
    line-height: calc(var(--ce-font-scale, 1) * 25px);
  }

  h4,h4.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 18px);
    line-height: calc(var(--ce-font-scale, 1) * 21px);
  }

  h5,h5.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 16px);
    line-height: calc(var(--ce-font-scale, 1) * 19px);
  }

  h6,h6.bp5-heading {
    font-size: calc(var(--ce-font-scale, 1) * 14px);
    line-height: calc(var(--ce-font-scale, 1) * 16px);
  }

}

// endregion running text
