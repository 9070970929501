@import "normalize.css";
//@import "@blueprintjs/core/lib/css/blueprint.css";
//@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
//@import "@blueprintjs/core/lib/scss/variables";

@import "@repalash/blueprint-styler/base/tokens";
@import "@repalash/blueprint-styler/base/blueprint.css";
@import "@repalash/blueprint-styler/base/blueprint-tokens.css";

:root {
    --shadow-bottom-right-color-1: #98b4ce7e;
    --shadow-top-left-color-2: #ffffffaa;
    --shadow-top-left-color-1: var(--shadow-top-left-color-2);

    --shadow-bottom-right-0: 3px 2px 5px;
    --shadow-bottom-right-1: 6px 4px 10px;
    --shadow-bottom-right-2: 13px 9px 20px;
    --shadow-bottom-right-3: 20px 13px 40px;
    --shadow-top-left-0: -1px -1px 1px 0px;
    --shadow-top-left-1: -1px -1px 2px 0px;
    --shadow-top-left-2: -2px -2px 6px 0px;
    --shadow-top-left-3: -2px -2px 8px 0px;
    --shadow-top-left-a-0: -2px -2px 6px 0px;
    --shadow-top-left-a-1: -5px -4px 14px 0px;
    --shadow-top-left-a-2: -10px -8px 30px 0px;
    --shadow-top-left-a-3: -15px -13px 50px 0px;
    --neu-elevation-0:
            var(--shadow-bottom-right-0) var(--shadow-bottom-right-color-1),
            var(--shadow-top-left-0) var(--shadow-top-left-color-1),
            var(--shadow-top-left-a-0) var(--shadow-top-left-color-2);
    --neu-elevation-1:
            var(--shadow-bottom-right-1) var(--shadow-bottom-right-color-1),
            var(--shadow-top-left-1) var(--shadow-top-left-color-1),
            var(--shadow-top-left-a-1) var(--shadow-top-left-color-2);
    --neu-elevation-2:
            var(--shadow-bottom-right-2) var(--shadow-bottom-right-color-1),
            var(--shadow-top-left-2) var(--shadow-top-left-color-1),
            var(--shadow-top-left-a-2) var(--shadow-top-left-color-2);
    --neu-elevation-3:
            var(--shadow-bottom-right-3) var(--shadow-bottom-right-color-1),
            var(--shadow-top-left-3) var(--shadow-top-left-color-1),
            var(--shadow-top-left-a-3) var(--shadow-top-left-color-2);

    .bp5-dark{
        --shadow-bottom-right-color-1: rgb(14 14 14 / 33%);
        --shadow-top-left-color-2: rgb(33 33 33 / 67%);
        --shadow-top-left-color-1: rgb(27 27 27 / 63%);
    }

    --pt-elevation-shadow-0: none;
    --pt-elevation-shadow-1: var(--neu-elevation-0);
    --pt-elevation-shadow-2: var(--neu-elevation-1);
    --pt-elevation-shadow-3: var(--neu-elevation-2);
    --pt-elevation-shadow-4: var(--neu-elevation-3);

}

//@import "@repalash/blueprint-styler/overrides/bpv3/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/bpv3/override.css";
//@import "@repalash/blueprint-styler/overrides/bpv3/override-tokens.css";

//@import "@repalash/blueprint-styler/overrides/antd/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/antd/override.css";
//@import "@repalash/blueprint-styler/overrides/antd/override-tokens.css";

//@import "@repalash/blueprint-styler/overrides/carbon/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/carbon/override.css";
//@import "@repalash/blueprint-styler/overrides/carbon/override-tokens.css";

//@import "@repalash/blueprint-styler/overrides/flat/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/flat/override.css";
//@import "@repalash/blueprint-styler/overrides/flat/override-tokens.css";

//@import "@repalash/blueprint-styler/overrides/fluent/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/fluent/override.css";
//@import "@repalash/blueprint-styler/overrides/fluent/override-tokens.css";

//@import "@repalash/blueprint-styler/overrides/pnnl/custom-tokens";
//@import "@repalash/blueprint-styler/overrides/pnnl/override.css";
//@import "@repalash/blueprint-styler/overrides/pnnl/override-tokens.css";


.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.bp5-dark{
    --pt-app-top-background-color: var(--black) !important;
    --navbar-background-color: var(--black) !important;
}

.bp5-vars, :root {
    --font-weight-normal: 500;
    --pt-border-radius: 7px;
    //--pt-font-size: 12px;
    //--pt-font-size-large: 14px;
    //--pt-font-size-small: 10px;
}
.bp5-dark {

}

@import './styles/global-utils.scss';

@import './styles/react-flow.scss';

@import './styles/code-drawer.scss';

@import "./styles/popup-dialog.scss";

@import "./styles/welcome-dialog.scss";

// https://cortexjs.io/mathlive/guides/customizing/
math-field {
    border-radius: var(--pt-border-radius);
    border: none;
    width: 100%;
    display: block;
    appearance: none;
    background: var(--input-background-color);
    outline: none;
    box-shadow: inset 0 0 0 0 hsla(var(--input-shadow-color-focus-hsl), 0), 0 0 0 0 hsla(var(--input-shadow-color-focus-hsl), 0), var(--pt-input-box-shadow);
    padding: 8px;
    //font-size: 1rem;
    font-size: 1.5rem;
    --caret-color: #333;
    .bp5-dark &{
        --caret-color: #eee;
    }
    --selection-color: var(--input-color);
    --selection-background-color: var(--pt-text-selection-color);
    --selection-background-color-focused: var(--pt-text-selection-color);
    --contains-highlight-background-color: hsla(var(--blue4-hsl), 0.3);
    //--contains-highlight-background-color: #eee;
    //--placeholder-color: #eee;
    //--placeholder-opacity: #eee;
    //--primary: #eee;
    //--smart-fence-color: #eee;
    //--smart-fence-opacity: #eee;
    //--text-font-family: #eee;
    //--correct-color: #eee;
    //--incorrect-color: #eee;
}
math-field::part(menu-toggle) {
    //display: flex;
    display: none !important;
}
math-field::part(virtual-keyboard-toggle) {
    //display: flex;
    display: none !important;
}

// region minimal tailwind

.flex{
    display: flex;
}

.flex-col{
    flex-direction: column;
}

.flex-row{
    flex-direction: row;
}

.flex-wrap{
    flex-wrap: wrap;
}

// endregion

// todo move to proper files

.vector-icon-container{
    display: flex;
    flex-direction: column;
    line-height: 0.9;
    width: 16px;
    align-items: center;
}

.bp5-drawer.global-settings-drawer{
    top: $pt-navbar-height !important;
    height: calc(100% - #{$pt-navbar-height});
    pointer-events: auto;
    > .bp5-drawer-body{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: calc($pt-grid-size * 2);
        gap: 0;
        //gap: calc($pt-grid-size * 0.5);
    }
}

//.bp5-overlay-open:has(.global-settings-drawer){
//    pointer-events: none;
//}
