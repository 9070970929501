@import "@repalash/blueprint-styler/base/tokens";

// this is required for resizable node, otherwise edges break when resizing because height is hard set.
.react-flow__node{
  height: auto !important;

  // set anti-aliasing for all children
  * {
      //transform: perspective(1px) translateZ(0); // dont use this, makes no diff.
      //will-change: transform; // Note - this makes other things aliased like icons.
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      backface-visibility: hidden;
      //-webkit-font-smoothing: subpixel-antialiased; // todo this can be tried or made an option for
  }
}

.card-node {
  position: relative;
  cursor: move !important;
  max-width: 100rem;
  width: 16rem; // this disables resizing. anyway we need to remove in the reactflow upgrade...
  min-width: 16rem;
  /*background: #ECF0F3;*/
  /*background: #333333;*/
  border-radius: calc($pt-grid-size * 2);
  padding-top: 3rem;
  gap: $pt-grid-size;
  display: flex;
  flex-direction: column;
  --outline-opacity: 0.3;
  outline-width: 2px; // todo maybe make param for this
  outline-style: solid;
  outline-color: transparent;

  .hide-nodes & {
    display: none;
  }

  .no-node-colors & {
    outline-color: hsla(var(--gray1-hsl), var(--outline-opacity)) !important;
    .bp5-dark &{
      outline-color: hsla(var(--light-gray1-hsl), var(--outline-opacity)) !important;
    }
  }

  .main-out-handle, .main-in-handle{
    top: 60px;
  }
  .pass-preserve-buffer-handle{
  }

  &.card-node-minimized{
    padding-top: calc($pt-grid-size * 3);
    .main-out-handle, .main-in-handle{
      top: 24px;
    }
    .pass-preserve-buffer-handle{
      top: 24px !important;
    }
  }
  &.card-node-selected{
    //outline-width: 3px !important; // todo
    --outline-opacity: 0.7;
  }

  > .react-flow__resize-control{
    width: auto;
    height: auto;
    opacity: 0.8;
    border: none !important;
    background: none;
    &.card-resize-handle-horiz{
      padding: 0 0 0 1rem;
      cursor: ew-resize;
    }
  }

  .bp5-control, .bp5-form-group, .bp5-label {
    margin-bottom: 0 !important;
  }

  --card-background: #E6EEF7;
  --card-background-dark: var(--dark-gray2);
  background: var(--card-background);
  //background: $light-gray5 !important;

  //--shadow-top-left-color-2: #ffffffaa;
  //--shadow-top-left-color-1: var(--shadow-top-left-color-2);

  //box-shadow: var(--neu-elevation-0);

  .bp5-dark & {
    background: var(--card-background-dark);
    //box-shadow:
    //        20px 13px 40px rgb(14 14 14 / 33%),
    //        -2px -2px 2px 0px rgb(27 27 27 / 63%),
    //        -15px -13px 50px 0px rgb(33 33 33 / 67%);
  }

  > .bp5-label, > .bp5-form-group, > .bp5-control, > .bp5-control-group{
    opacity: 0.75;
  }

  .react-flow__node-renderTarget & {
    --shadow-top-left-color-1: hsla(var(--red5-hsl),0.1) !important;
    outline-color: hsla($red1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($red4-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-texture & {
    --shadow-top-left-color-1: hsla(var(--green5-hsl),0.1) !important;
    outline-color: hsla($green1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($green4-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-scene3d & {
    --shadow-top-left-color-1: hsla(var(--blue5-hsl),0.1) !important;
    outline-color: hsla($blue1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($blue4-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-camera & {
    --shadow-top-left-color-1: hsla(var(--orange5-hsl),0.1) !important;
    outline-color: hsla($orange1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($orange4-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-material & {
    --shadow-top-left-color-1: hsla(var(--sepia5-hsl),0.1) !important;
    outline-color: hsla($sepia1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($sepia4-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-shaderSnippet & ,
  .react-flow__node-shaderMathFunction & ,
  .react-flow__node-shaderMathDefine & ,
  .react-flow__node-shader & {
    --shadow-top-left-color-1: hsla(var(--gray5-hsl),0.1) !important;
    outline-color: hsla($gray1-hsl,var(--outline-opacity));
    .bp5-dark &{
      outline-color: hsla($gray5-hsl,var(--outline-opacity));
    }
  }
  .react-flow__node-shaderPass & ,.react-flow__node-renderPass & {
    --shadow-top-left-color-1: hsla(var(--red1-hsl),0.1) !important;
    outline-color: hsla($red1-hsl,calc(var(--outline-opacity) + 0.1));
    .bp5-dark &{
      outline-color: hsla($red5-hsl,calc(var(--outline-opacity) + 0.1));
    }
  }
  .react-flow__node-richText & , {
    --shadow-top-left-color-1: hsla(var(--light-gray1-hsl), 0.1) !important;
    outline-color: hsla($dark-gray5-hsl, calc(var(--outline-opacity)));

    .bp5-dark & {
      outline-color: hsla($light-gray5-hsl, calc(var(--outline-opacity)));
    }
  }

  .react-flow__node-vector & , {
    --shadow-top-left-color-1: hsla(var(--gold1-hsl), 0.1) !important;
    outline-color: hsla($gold1-hsl, calc(var(--outline-opacity)));

    .bp5-dark & {
      outline-color: hsla($gold5-hsl, calc(var(--outline-opacity)));
    }
  }

  // todo this should be for minimal card.
  .react-flow__node-richText & ,{
    --outline-opacity: 0;
    &.card-node-selected{
      //outline-width: 3px !important; // todo
      --outline-opacity: 0.1;
    }

    outline-width: 1px;
    border-radius: 0.25rem !important;
  }

  .react-flow__node-shaderPass & ,
  .react-flow__node-renderPass & ,
  .react-flow__node-shaderSnippet & ,
  .react-flow__node-renderToScreen & ,
  .react-flow__node-shaderMathFunction & ,
  .react-flow__node-shaderMathDefine & ,
  .react-flow__node-texture & ,
  .react-flow__node-richText &{
    width: 100%; // allow resizing
  }


}

.node-header{
  width: 100%;
  height: 2rem;
  position: absolute;
  top: 0.5rem;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: $pt-grid-size;
  padding-right: $pt-grid-size;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: visible;

  .bp5-dark &{
    //background: $dark-gray2;
  }

  span{
    min-width: 0; // for flex
  }
  .node-header-title{
    overflow: hidden;
    text-overflow: ellipsis;

    color: rgba(33, 33, 33, 0.69);

    .bp5-dark &{
      color: rgba(240, 240, 240, 0.69);
    }

    &.bp5-small{
      font-size: $pt-font-size-small;
    }
  }

  .single-side-button{
    position: absolute;
    right: $pt-grid-size;
  }
}
