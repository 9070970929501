@import "./bp-fixes";

.grow{
  flex-grow: 1;
}

// used eg for color picker
.mouse-tooltip{
  position: absolute;
  z-index: 999;
  padding: $pt-grid-size !important;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  max-width: 20rem;
  transition: none !important;
}

.bp5-flex-label {
  display: flex !important;
  justify-content: space-between;
  gap: $pt-grid-size;
}

// expanding Name input in bp5-flex-label
.bp5-flex-label > .bp5-input-group{
  flex: 1;
}

.bp5-dark .bp5-dialog-footer-fixed {
  background: $dark-gray2;
}


//.bp5-dark .bp5-navbar {
//    background: $black;
//}

