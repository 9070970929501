@import "@repalash/blueprint-styler/base/tokens";

.react-flow__handle{
  height: 16px;
  width: 16px;
  background: $gray4;

  .no-handle-colors &{
    background: $gray4 !important;
  }

  .hide-handles &{
    opacity: 0 !important;
  }
}
.bp5-dark .react-flow__handle{
  background: $gray5;
  border: none;
}
//.bp5-control-group.bp5-small .react-flow__handle{
//  margin-top: 2px;
//  margin-bottom: 2px;
//}
.react-flow__handle-right {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%)
}
.react-flow__handle-left {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%)
}
.react-flow__handle-top {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)
}
.react-flow__handle-bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)
}


.object3d-handle{
  //background: #f21111;
  background: $blue1;
  .bp5-dark &{
    background: $blue4;
  }
}
.texture-handle{
  //background: #51f211;
  //background: $gray4;
  background: $green1;
  .bp5-dark &{
    background: $green4;
  }
}
.shader-frag-handle{
  background: $cerulean1;
  .bp5-dark &{
      background: $cerulean4;
  }
}
.camera-handle{
  background: $orange1;
  .bp5-dark &{
    background: $orange4;
  }
}
.buffer-handle{
  background: $red1;
  .bp5-dark &{
    background: $red4;
  }
}
.material-handle{
  background: $sepia4;
  .bp5-dark &{
    background: $sepia3;
  }
}
.shader-handle{
  background: $gray1;
  .bp5-dark &{
    background: $gray4;
  }
}
.vector-handle{
  background: $gold4;
  .bp5-dark &{
    background: $gold3;
  }
}
.string-handle{
  border: none; // todo add everywhere?
  background: hsla($gray1-hsl, 0.1);
  .bp5-dark &{
    background: hsla($gray4-hsl, 0.1);
  }
}

.buffer-handle.react-flow__handle-right{
  background: linear-gradient(to bottom,
          $red1 0%,
          $red1 50%,
          $green1 50%,
          $green1 100%);
  .bp5-dark &{
    background: linear-gradient(to bottom,
          $red4 0%,
          $red4 50%,
          $green4 50%,
          $green4 100%);
  }
}

.slot-in-control-label{
  //style={{marginLeft:"15px", marginRight:"10px"}}
  margin-left: calc($pt-grid-size * 1.5);
}
